'use client'

import { Slot } from '@radix-ui/react-slot'
import cn from 'classnames'
import React, { forwardRef } from 'react'

import { Icon } from '@/components/Icon'
import { type IconName } from '@/components/Icon/icons'

import styles from './styles.module.css'

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  /**
   * The child elements of the button, e. g. text
   */
  children?: React.ReactNode

  /**
   * Change the component to the HTML tag or custom component of the only child. This will merge the original component props with the props of the supplied element/component and change the underlying DOM node.
   */
  asChild?: boolean

  /**
   * The border radius of the button
   */
  roundness?: '3' | '4' | '8' | 'full'

  /**
   * The size of the button
   */
  size?: 24 | 34 | 40 | 44 | 48 | 56

  /**
   * The general appearance of a button
   */
  appearance?: 'highlight' | 'bordered' | 'filled' | 'plain'

  /**
   * An optional icon
   */
  icon?: IconName

  /**
   * The icon size
   */
  iconSize?: 16 | 18 | 22

  /**
   * The icon position relative to other button children
   */
  iconPosition?: 'before' | 'after'

  /**
   * The icon has a gradient fill on hover.
   */
  iconGradientHover?: boolean

  /**
   * The button's theme
   */
  theme?: 'light' | 'dark' | 'medium'

  /**
   * If true, the button's width and height will be equal. Can be used to force a square or circle shape (in combination with the "roundness" property).
   */
  fixedAspectRatio?: boolean

  /**
   * The button type is added as a CSS class to make styling easier for special buttons.
   */
  buttonType?: 'square' | 'icon'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      asChild,
      roundness = 'full',
      size = 34,
      appearance = 'filled',
      icon = undefined,
      iconSize = 18,
      iconPosition = 'before',
      iconGradientHover = false,
      theme = 'dark',
      fixedAspectRatio = false,
      className,
      buttonType,
      ...rest
    },
    ref
  ) => {
    const buttonClass = cn(className, 'group/iconHover', styles.button, `button-size-${size}`, {
      [styles['has-fixed-aspect-ratio']]: fixedAspectRatio,

      [styles.filled]: appearance === 'filled',
      [styles.bordered]: appearance === 'bordered',
      [styles.plain]: appearance === 'plain',
      [styles.highlight]: appearance === 'highlight',

      [styles['button-size-24']]: size === 24,
      [styles['button-size-34']]: size === 34,
      [styles['button-size-40']]: size === 40,
      [styles['button-size-44']]: size === 44,
      [styles['button-size-48']]: size === 48,
      [styles['button-size-56']]: size === 56,

      [styles['roundness-3']]: roundness === '3',
      [styles['roundness-4']]: roundness === '4',
      [styles['roundness-8']]: roundness === '8',
      [styles['roundness-full']]: roundness === 'full',

      [styles['theme-light']]: theme === 'light',
      [styles['theme-dark']]: theme === 'dark',
      [styles['theme-medium']]: theme === 'medium',

      [styles['icon-before']]: iconPosition === 'before',
      [styles['icon-after']]: iconPosition === 'after',

      [styles['button-type-square']]: buttonType === 'square',
      [styles['button-type-icon']]: buttonType === 'icon'
    })

    const iconClass = cn(styles.icon, {
      [styles['icon-16']]: iconSize === 16,
      [styles['icon-18']]: iconSize === 18,
      [styles['icon-22']]: iconSize === 22
    })

    const Comp = asChild === true ? Slot : 'button'
    let contents

    if (asChild === true) {
      if (React.Children.toArray(children).length > 1 || React.Children.toArray(children).length === 0) {
        return React.Children.only(null)
      }

      const child = children as React.ReactElement
      const grandChild = child.props.children

      const childContents = (
        <>
          {iconPosition === 'before' && icon != null && (
            <Icon className={iconClass} hasGradient={iconGradientHover} src={icon} />
          )}
          {grandChild}
          {iconPosition === 'after' && icon != null && (
            <Icon className={iconClass} hasGradient={iconGradientHover} src={icon} />
          )}
        </>
      )

      contents = (
        <Slot className={buttonClass} {...rest} ref={ref}>
          {React.createElement(child.type, child.props, childContents)}
        </Slot>
      )
    } else {
      contents = (
        <>
          {iconPosition === 'before' && icon != null && (
            <Icon className={iconClass} hasGradient={iconGradientHover} src={icon} />
          )}
          {children}
          {iconPosition === 'after' && icon != null && (
            <Icon className={iconClass} hasGradient={iconGradientHover} src={icon} />
          )}
        </>
      )
    }

    return (
      <Comp className={buttonClass} {...rest} ref={ref}>
        {contents}
      </Comp>
    )
  }
)

Button.displayName = 'Button'

export default Button
