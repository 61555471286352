'use client'

import { useTranslations } from 'next-intl'

import customLottieAnimation from '@/assets/animations/lottie-error-animation-light.json'
import ErrorScreen, { type ErrorScreenProps } from '@/components/ErrorScreen'
import RefreshButton from '@/components/ErrorScreen/RefreshButton'

const Error = ({ title, message }: ErrorScreenProps): JSX.Element => {
  const t = useTranslations('error')

  return (
    <ErrorScreen
      backgroundColor="bg-greyblue-85"
      buttons={<RefreshButton />}
      customAnimation={{ json: customLottieAnimation }}
      message={message ?? t('defaultMessage')}
      title={title ?? t('defaultTitle')}
    />
  )
}

export default Error
