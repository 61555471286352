import { useTranslations } from 'next-intl'

import FilledButton from '../Button/FilledButton'

const RefreshButton = (): JSX.Element => {
  const t = useTranslations('error')

  return (
    <FilledButton
      icon="ArrowRotateRight"
      onClick={() => {
        window.location.reload()
      }}
    >
      {t('buttons.refresh.label')}
    </FilledButton>
  )
}

export default RefreshButton
