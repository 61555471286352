import { forwardRef } from 'react'

import Button, { type ButtonProps } from '..'

type FilledButtonProps = Omit<ButtonProps, 'appearance'>

const FilledButton = forwardRef<ButtonProps, FilledButtonProps>(({ ...props }, ref) => {
  return <Button appearance="filled" {...props} />
})

FilledButton.displayName = 'FilledButton'

export default FilledButton
